.App {
    text-align: center;
    max-width: 100%;
    overflow-x: hidden;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

#asymptomatic {
    display: flex;
    position: absolute;
    top: 1%;
    background-color: white;
    padding: 5px 45px;
    border-radius: 8px;
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
    min-height: 64px;
}

@media (max-width: 600px) {
    #asymptomatic {
        position: absolute;
        top: 1%;
        left: 50px;
        background-color: white;
        padding: 0px 30px;
        border-radius: 8px;
        box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
    }
}

@media (min-width: 600px) {
    #asymptomatic{
        right: 40%
    }
}