#plantation-infoWindow {
    margin: 0;
    display: flex;
    flex-direction: row;
    text-align: center;
    width: 240px;
    height: 15%;
}

#plantation-info-detail {
    padding: 13px 16px;
    display: flex;
    flex-direction: column;
    width: 100%;
}

#stars {
    display: flex;
    margin-top: 8px;
}

#plantation-trees-info {
    display: flex;
    justify-content: space-between;
    margin-top: 11px;
    margin-bottom: 10px;
    width: 100%;
}

#plantation-infoImage {
    width: 112px;
    height: 112px;
}

#plantation-tp {
    text-align: left;
    font-size: 16px;
    letter-spacing: 0.21px;
}

#plantation-tt {
    text-align: left;
    font-size: 16px;
    letter-spacing: 0.24px;
    color: #00000080;
    margin-top: 5px;
}

.checked {
    color: var(--primary)
}

#tree-infoWindow {
    margin: 0;
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 160px;
    height: 232px;
}

#tree-info-detail {
    padding: 13px 16px;
    display: flex;
    flex-direction: column;
    width: 100%;
}

#tree-infoImage {
    width: 160px;
    height: 168px;
}

#tree-tp {
    text-align: left;
    font-size: 16px;
    letter-spacing: 0.21px;
}

#tree-tt {
    text-align: left;
    font-size: 14px;
    letter-spacing: 0.24px;
    color: #00000080;
}

#city-infoWindow {
    margin: 0;
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 240px;
    height: 72px;
}

.gm-style .gm-style-iw-c {
    padding: 0px !important
}

.gm-style .gm-style-iw-t::after {
    content: none !important;
}

.gm-style-iw-d {
    overflow: hidden !important;
    max-height: 232px !important;
}